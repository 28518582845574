/* fonts */
@font-face {
  font-family: 'Poppins';
  font-weight: 400;
  font-display: swap;
  /* ascent-override: 80.916%;
  descent-override: 19.084%; */
  src: url('../fonts/Poppins-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 600;
  font-display: swap;
  src: url('../fonts/Poppins-SemiBold.woff2') format('woff2');
}

/* PandaCss */
@layer reset, base, tokens, recipes, utilities;
